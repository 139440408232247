import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const CelaiWest = () => (
  <Layout>
    <SEO
      title="Natural Hair Model and Entrepreneur Celai West"
      description="We talk to the talented and incredibly hard working entrepreneur Celai West. Celai tells us about her unique homemade hair tea!"
    />
    <h1>Entrepreneurship, Modeling and Natural Hair with Celai West</h1>
    <h2 style={{color:"Orchid"}}>Celai tells us the secrets she uses to get beautiful runway ready natural hair</h2>

    <p>This week we talk to the talented and incredibly hard working entrepreneur Celai West. At such a young age, she has already achieved what many of us dream of and we get to hear how she got started. Celai gets into her unique hair routine that helps protect from the damage that excessive styling from modeling can cause. We learned a lot and can't wait for you to read this one.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/AVUnX4i.jpg"
        alt="Celai West photo by creative soul natural afro hair care"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="http://creativesoulphoto.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >@creativesoulphoto</a> & Hair by <a
        href="https://www.instagram.com/imlachanda/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >@imlachanda</a></p>
    </div>

    <h3>Hello Celai, tell us a bit about yourself</h3>
    <p>My name is Celai (suh-LAY) West and I am a quirky 10 year old girl who's goal is to change the standard of beauty in the modeling industry. I keep hearing that I recently changed the standard of runway when I became the youngest and shortest runway model to walk amongst on all adult lineup in NYFW. I am also an actress and the CEO of my own t-shirt line, <a
        href="https://www.thechattychick.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      ThechattyChick.com
      </a>.</p>

    <h3>How did you get into modeling and fashion?</h3>
    <p>I did my first photoshoot when I was 3, almost 4, and I fell in love with modeling. I got my first modeling contract when I was 4 but mom kinda freaked out and didn't sign it. She really wasn't sure if this was something *I* wanted to do. I got my second modeling contract when I was 5 and mom decided to let me do it. When I booked my first shoot, I knew I wanted to do this forever!</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/Wj7x7ND.jpg"
        alt="Celai West Ugochi Iwuaba"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey", marginBottom:"0px"}}>Celai walking for <a
        href="https://www.instagram.com/ugochi_iwuaba/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >@ugochi_iwuaba</a> at <a
        href="https://www.instagram.com/thesocietyfashionweek/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >@thesocietyfashionweek</a></p> 
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photographer <a
        href="https://www.instagram.com/christinaweeksphotography/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >@christinaweeksphotography</a></p>
    </div>

    <h3>What is it like being successful early on and what are your career highlights so far?</h3>
    <p>I think it's pretty cool. I mean, I get to do what I love to do and when I get older, I'll have lots of experience.</p>
    <p>A highlight in my career would be the first time I walked for an adult designer, Mario De La Torre and then it would also have to be this past NYFW because I closed the opening show at Style Fashion Week for David Tupaz and then I opened for Ugochi Iwuaba, both adult designers.</p>

    <h3>What would you like to achieve next?</h3>
    <p>I would love to walk for Malan Breton because he opened the very first NYFW show I had ever seen in person and I was just IN LOVE with his pieces, his models and his whole show!</p>

    <h3>You are a very focused and busy individual, what do you do to relax?</h3>
    <p>Well, when in home, I love to hang out in my pj's, watch the Disney Channel or movies with my family or watch YouTube videos.</p>

    <h3>If you could collaborate with anyone, who would it be and what would you create?</h3>
    <p>I would collaborate with my "model mom" Liris Crosse (<a
        href="https://www.instagram.com/lirisc/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      @LirisC
      </a>) in her Diversity Project because I think I represent the shorter models and also models with kinky hair. Plus, she's just so amazing so I'd love to work with her on ANYTHING.</p>

      <h3>What does your natural hair mean to you?</h3>
      <p>It means...everything actually. I love it! I love how big it is and I love that I can do almost anything with it.</p>

      <h3>How would you describe your hair and what is your family background?</h3>
      <p>It's kinky and coily and big (<a
        href="https://en.wikipedia.org/wiki/Afro-textured_hair"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      4b/c
      </a>). My mom is black and my dad is black.</p>

      <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/Pc0XARt.jpg"
        alt="Celai West Julian J Peeples"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photographer: <a
        href="https://www.peeplesphoto.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >Julian J Peeples</a></p>
    </div>

    <h3>Talk us through your hair routine</h3>
    <p>Well, mom loves to use our hair tea that we made together. It helps to keep my hair and scalp healthy because modeling can cause lots of breakage and damage. Over the summer, we decided to not stretch my hair because it's hard to keep it stretched AND moisturized. So we added aloe vera juice to our hair tea and my hair loves it! We don't wash too often and we do protein and silk treatments about once a month. And mom uses beef tallow to seal my ends. My hair definitely loves this new routine we are doing.</p>

    <h3>How did you view your hair growing up and were other people interested in it?</h3>
    <p>I've always loved my hair for as long as I can remember and people always want to touch my hair. I mean, I just think that if I love my hair this much, maybe other people do too and they are just curious about it.</p>

    <h3>Do you have a role model that helps you?</h3>
    <p>My mom is definitely my role model when it comes to my hair and she teaches me everything about it. She lets me apply products to it when we wash and condition it so I can see how different things make my hair feel. And she shows me that natural hair can be fancy too when she styles it.</p>

    <h3>Are there any resources you use now?</h3>
    <p>Mom and I love watching hair tutorials of hair that looks just like mine so we like <a
        href="https://www.youtube.com/Naptural85"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      @naptural85
      </a> and <a
        href="https://www.instagram.com/naturallytemi/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      @naturallytemi
      </a></p>

      <h3>What happened when you started doing your hair yourself?</h3>
      <p>Well, I mostly don't do it myself but I'm starting to learn how to. Mom explains things to me when she does my hair so I'll know. I know it would be a little hard for me to learn it all but I really want to learn so when I get older and my hair gets longer, I'll know how to take care of it myself.</p>

      <h3>Any funny stories? </h3>
      <p>Well, this one time, a girl sitting behind me in my 2nd grade class told me that my hair was in her way. So I told her "No, your HEAD is in the way of my hair!". And I just turned back around and kept doing my work. *shrugs*</p>

      <h3>What advice would you give for taking care of natural hair?</h3>
      <p>Just keep it moisturized, don't use too much heat on it and NO PERMS!!!</p>

      <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/9bcIQw7.jpg"
        alt="Celai West Julian J Peeples b and w"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Stylist/Hair/MUA: <a
        href="https://www.instagram.com/ugochi_iwuaba/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >@ugochi_iwuaba</a> & Photographer: <a
        href="https://www.instagram.com/julianjpeeples/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >@julianjpeeples</a></p>       
    </div>

    <h3>What is your favourite hairstyle right now?</h3>
    <p>Well, the other day Miss LaChanda GATSON (<a
        href="https://www.instagram.com/imlachanda/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      @imlachanda
      </a>) did my hair for a shoot with CreativeSoul (<a
        href="https://www.instagram.com/creativesoulphoto/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      @creativesoulphoto
      </a>) and I want to wear it as long as I can! She put 4 braids on one side and put beads on the ends. It's definitely my favorite.</p>

      <h3>Do you have any hair plans for the future?</h3>
      <p>Well, now that is getting cold out, we want to do more protective styles and wear more headwraps. Modeling doesn't really allow me to wear braids so my protective styles only last about a week.</p>

      <h3>What are you passionate about?</h3>
      <p>I'm passionate about changing the industry's view of kinky hair so we can  have more kinky hair models in ads and kinky hair actresses on tv. And I also want to show little girls with big hair that their hair is beautiful and perfect the way it is.</p>

      <h3>Why do you think the industry doesn't like kinky hair?</h3>
      <p>Well, because when I'm on set, I can see the look on the hair stylists' face when I walk in. Or sometimes, the other model I'm shooting with will wrap before I even get out of hair & makeup so then I start to feel like that's why I don't see any kinky haired models in ads or on the shows I watch on TV.</p>
      <p>But then mom and I started doing our hair tutorials as a way to educate people about how to style and take care of kinky hair. It's really easy once you get the hang of it!</p>

      <h3>Can you tell us about any plans for the future?</h3>
      <p>My plans are to just keep doing well in school and to keep following God's path for me.</p>

      <h3>How can we follow/ contact you?</h3>
      <p>You can follow me on social media at:</p>
      <p><a
        href="http://www.instagram.com/celaiwest"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      www.Instagram.com/celaiwest
      </a></p>

      <p><a
        href="http://www.facebook.com/celaiwest"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      www.Facebook.com/celaiwest
      </a></p>

      <p><a
        href="http://www.twitter.com/celaiwest"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      www.Twitter.com/celaiwest
      </a></p>

      <p><a
        href="http://www.youtube.com/celaiwest"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      www.YouTube.com/celaiwest
      </a></p>

      <p>Or you can visit my website at <a
        href="http://www.celaiwest.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      www.CelaiWest.com
      </a></p>


    {/* <h2>Subtitle</h2> */}

    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}




      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/talking-with-tyler/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            Social Media Natural Hair Influencer Tyler Talks Natural Hair Care
            </Link>{" "}
            - Tyler the natural hair enthusiast from Grenada talks about her routine and her favourite products. She has some amazing hairstyles for black women...
          </p>
          <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>          
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default CelaiWest
